// eslint-disable-next-line no-undef
MicroModal.init({
	disableScroll: true,
	onClose: (modal) => {
		const iframe = modal.querySelector('iframe');

		if (null !== iframe) {
			iframe.setAttribute('src', '');
		}
	},
	onShow: (modal) => {
		const iframe = modal.querySelector('iframe');
		if (null !== iframe) {
			const src = iframe.getAttribute('data-src');
			iframe.src = src;
		}
	},
});
